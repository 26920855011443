import React from "react";
import "./Navigation.css";
import {MenuItems} from "./MenuItems";
import MenuItem from "./MenuItem";

const Navigation = (props) => {
    const {handleMenuOpen} = props;

    return (
        <>
            <ul className="header-menu">
                {MenuItems.map((item, index) => {
                    return (
                        <MenuItem
                            item={item}
                            key={index}
                            handleMenuOpen={handleMenuOpen}
                        />
                    );
                })}
            </ul>
        </>
    );
};

export default Navigation;
