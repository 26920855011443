import React, {useEffect, useState} from "react";
import "./About.css";
import PagesHero from "../../components/pages-hero/PagesHero";
import Logo from "../../img/logo.svg";

const About = () => {
    const [title, setTitle] = useState("O nas - Mainsoft");
    useEffect(() => {
        document.title = title;
    }, [title]);
    return (
        <>
            <main className="main-container">
                <PagesHero HeroTitle={"O nas"} />
                <div className="about-us-container">
                    <div className="about-text">
                        <img alt="Logo Firmy Mainsoft" className="about-us-logo" src={Logo} />
                        <h2>
                            O firmie
                            <strong> Mainsoft Sp. z o.o.</strong>
                        </h2>

                        <p>
                            Firma Mainsoft Sp. z o.o. zajmuję się kompleksową obsługą informatyczną przedsiębiorstw i instytucji. Świadczymy usługi w obszarze szeroko rozumianego outsourcingu IT, bezpieczeństwa informacji, usług handlowych i
                            montażowych z zakresu sieci teleinformatycznych i serwerowni. Zajmujemy się również sprzedażą i wdrożeniami programów klasy ERP oraz programów dla służby zdrowia.
                        </p>
                    </div>
                </div>
            </main>
        </>
    );
};

export default About;
