import React from "react";
import {Link} from "react-router-dom";
import "./SpecialBtn.css";

const SpecialBtn = (props) => {
    const {BtnText, BtnLink, BtnClass} = props;
    return (
        <>
            <Link to={BtnLink} className={BtnClass}>
                {BtnText}
            </Link>
        </>
    );
};

export default SpecialBtn;
