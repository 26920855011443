import React from "react";
import "./ContactMainInfo.css";

const ContactMainInfo = (props) => {
    const {
        ContactTitle,
        ContactAdressLine1,
        ContactAdressLine2,
        ContactIsReversed,
        ContactMainImg,
        ContactImgAlt,
        ContactIframe,
        ContactLink,
        References,
    } = props;
    return (
        <>
            <div
                ref={References}
                className={
                    ContactIsReversed
                        ? "contact-info-container reversed"
                        : "contact-info-container"
                }
            >
                <div className="contact-info-left">
                    <div className="contact-info-title">
                        <h2>
                            Oddział w <span>{ContactTitle}</span>
                        </h2>
                    </div>
                    <div className="contact-text-container">
                        <ul>
                            <li>
                                <strong>Mainsoft Sp. z o. o</strong>
                            </li>
                            <li>
                                <strong>Adres: </strong>
                            </li>
                            <a
                                href={ContactLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {ContactAdressLine1} <br />
                                {ContactAdressLine2}
                            </a>
                        </ul>
                        <iframe
                            src={ContactIframe}
                            className="iframe-map"
                            frameBorder="0"
                            aria-hidden="false"
                            tabIndex="0"
                            title="Mapa dojazdu"
                        />
                    </div>
                </div>
                <div className="contact-info-right">
                    <picture>
                        <img src={ContactMainImg} alt={ContactImgAlt} />
                    </picture>
                </div>
            </div>
        </>
    );
};

export default ContactMainInfo;
