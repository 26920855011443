import React, {useState} from "react";
import NavbarLogo from "./NavbarLogo";
import Navigation from "./navigation/Navigation";
import AboveNav from "../../components/over-nav/AboveNav";
import "./Navbar.css";
import Hamburger from "./hamburger/Hamburger";

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(true);
    const handleMenuOpen = () => {
        setMenuOpen(!menuOpen);
    };

    const [menuButton, setMenuButton] = useState(true);

    return (
        <header className="header">
            <AboveNav />
            <div className="navbar">
                <NavbarLogo />

                <nav className={menuOpen ? "nav-menu" : "nav-menu open"}>
                    <Navigation handleMenuOpen={handleMenuOpen} />
                </nav>

                {menuButton ? (
                    <Hamburger
                        menuOpen={menuOpen}
                        handleMenuOpen={handleMenuOpen}
                    />
                ) : (
                    ""
                )}
            </div>
        </header>
    );
};

export default Navbar;
