import React from "react";
import "./Tooltip.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import IngLogo from "./../../Assets/img/ing.png";

const Tooltip = (props) => {
    const {TooltipText} = props;
    return (
        <>
            <span className="tooltip-container">
                <div className="tooltip-btn">
                    <button type="button">
                        <FontAwesomeIcon icon={faCircleInfo} color="#0162a0" />
                    </button>
                </div>
                <div className="tooltip-text">
                    <img src={IngLogo} alt="" />
                    {TooltipText}
                </div>
            </span>
        </>
    );
};

export default Tooltip;
