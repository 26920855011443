import React from 'react'
import {BrowserRouter as Router} from "react-router-dom";
import PageRoutes from "./PageRoutes";
import Navbar from "./Layouts/Navbar/Navbar";
import Footer from "./Layouts/Footer/Footer";
import PageUp from "./components/page-up/PageUp";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import "./css/App.css";
import "./css/Variables.css";

function App() {
    return (
        <>
            <Router>
                <ScrollToTop />
                <Navbar />
                <PageRoutes />
                <Footer />
                <PageUp />
            </Router>
        </>
    );
}

export default App;
