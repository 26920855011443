import React from "react";
import {Link} from "react-router-dom";
import sliderBackgroundIT from "../../img/backgroundDarkerLow.jpg";
import sliderBackgroundWebsite from "../../img/slider-background-website.jpg";
import sliderBackground3 from "../../img/slider-background-3.jpg";
import "./Slider.css";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import {Pagination, Navigation, Autoplay, EffectFade} from "swiper";

const Slider = () => {
    return (
        <>
            <Swiper
                pagination={{
                    type: "progressbar",
                }}
                speed={1500}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                effect={"fade"}
                fadeEffect={{
                    crossFade: true,
                }}
                keyboard={{
                    enabled: true,
                }}
                navigation={true}
                slidesPerView={1}
                modules={[Pagination, Navigation, Autoplay, EffectFade]}
                loop={true}
                className="mySwiper"
            >
                <SwiperSlide className="slider-position">
                    <img src={sliderBackgroundIT} alt="Grafika tła" className="slider" />
                    <div className="slider-text-container">
                        <h1 className="slider-heading-text">ADMINISTRACJA SERWERÓW</h1>
                        <p className="header_text_low">Projektowanie, wykonanie i zarządzanie sieciami komputerowymi</p>
                        <Link to="/error" className="btn_start btn_second">
                            Sprawdź!
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slider-position">
                    <img src={sliderBackgroundWebsite} alt="Grafika tła" className="slider" />

                    <div className="slider-text-container">
                        <h1 className="slider-heading-text">STRONY I APLIKACJE INTERNETOWE</h1>
                        <p className="header_text_low">Tworzymy i projektujemy skuteczne, nowoczesne i responsywne aplikacje i strony internetowe</p>
                        <Link to="/error" className="btn_start btn_second">
                            Sprawdź!
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slider-position">
                    <img src={sliderBackground3} alt="Grafika tła" className="slider" />
                    <div className="slider-text-container">
                        <h1 className="slider-heading-text">OBSŁUGA INFORMATYCZNA</h1>
                        <p className="header_text_low">Nasze usługi informatyczne wspierają wiele firm</p>
                        <Link to="/error" className="btn_start btn_second">
                            Sprawdź!
                        </Link>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
};

export default Slider;
