import React from "react";
import {Link} from "react-router-dom";

const Dropdown = ({submenus, dropdown}) => {
    return (
        <ul className={`dropdown ${dropdown ? "header-menu-item-content" : "header-menu-item-content"}`}>
            {submenus.map((submenu, index) => (
                <li key={index} className="header-dropdown-item">
                    {!submenu.isExternal ? (
                        <Link className="header-dropdown-link" to={submenu.url}>
                            {submenu.title}
                        </Link>
                    ) : (
                        <a className="header-dropdown-link" href={submenu.url}>
                            {submenu.title}
                        </a>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default Dropdown;
