import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faHouseLaptop,
    faLaptop,
    faWindowMaximize,
    faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import "./services.css";

const ServicesHomePage = () => {
    return (
        <section className="homepage-about-container">
            <div className="homepage-about-header">
                <h1>Usługi</h1>
            </div>

            <div className="services-container">
                <div className="services-item">
                    <FontAwesomeIcon
                        icon={faWindowMaximize}
                        size="3x"
                        className="services-item-icon"
                    />
                    <div className="services-item-text">
                        Projektowanie, wykonanie i zarządzanie sieciami
                        komputerowymi
                    </div>
                </div>

                <div className="services-item">
                    <FontAwesomeIcon
                        icon={faHouseLaptop}
                        size="3x"
                        className="services-item-icon"
                    />
                    <div className="services-item-text">
                        Kompleksowa obsługa firm od strony informatycznej
                    </div>
                </div>

                <div className="services-item">
                    <FontAwesomeIcon
                        icon={faFloppyDisk}
                        size="3x"
                        className="services-item-icon"
                    />
                    <div className="services-item-text">
                        Odzyskiwanie oraz nieodwracalne usuwanie danych
                    </div>
                </div>

                <div className="services-item">
                    <FontAwesomeIcon
                        icon={faLaptop}
                        size="3x"
                        className="services-item-icon"
                    />
                    <div className="services-item-text">
                        Sprzedaż i serwis sprzętu IT
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesHomePage;
