import React, {useEffect, useState} from "react";
import "./PageUp.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";

const PageUp = () => {
    const [isPageUpActive, setIsPageUpActive] = useState(false);

    const togglePageUpBtn = () => {
        if (window.pageYOffset > 500) {
            setIsPageUpActive(true);
        } else {
            setIsPageUpActive(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", togglePageUpBtn);

        return () => {
            window.removeEventListener("scroll", togglePageUpBtn);
        };
    }, []);

    return isPageUpActive ? (
        <div className="page-up-btn-container">
            <button className="page-up-btn" onClick={scrollToTop}>
                <FontAwesomeIcon icon={faChevronUp} size="2x" />
            </button>
        </div>
    ) : (
        ""
    );
};

export default PageUp;
