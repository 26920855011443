import React from "react";
import "./Footer.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MainsoftFooterLogo from "../../Assets/svg/mainsoft-logo-white.svg";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare, faLinkedin} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-adress">
                        <h3 className="footer-heading">Dane kontaktowe</h3>
                        <ul>
                            <li>
                                <FontAwesomeIcon icon={faPhone} size="lg" /> <a href="tel:+48572324411">+48 572 324 411</a>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faEnvelope} size="lg" />
                                {"  "}
                                <a href="mailto:kontakt@mainsoft.pl">kontakt@mainsoft.pl</a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/mainsoftpl" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faFacebookSquare} size="lg" />
                                    <span className="over-nav-text">Facebook</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/mainsoftpl/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faLinkedin} size="lg" />
                                    <span className="over-nav-text">LinkedIn</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <img src={MainsoftFooterLogo} alt="Białe logo firmy Mainsoft" className="footer-logo" />
                    <div className="footer-firm">
                        <h3 className="footer-heading">Dane firmy</h3>
                        <ul>
                            <li>
                                <strong>NIP:</strong> 5792264087
                            </li>
                            <li>
                                <strong>REGON:</strong> 368852525
                            </li>
                            <li>
                                <strong>KRS:</strong> 0000706370
                            </li>
                            <li>
                                <strong>Nr konta bankowego: </strong>
                                <br />
                                32 1050 1764 1000 0090 8032 7589
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-low">
                    <p>Mainsoft © 2024 Wszelkie prawa zastrzeżone</p>
                </div>
            </footer>
        </>
    );
};

export default Footer;
