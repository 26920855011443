import React, {useState} from "react";
import {ContactTabMenu} from "./ContactMainCardEmployee";
import "./ContactMainCard.css";
import "./ContactMainInfo.css";
import Tooltip from "./Tooltip";

const ContactMainCard = (props) => {
    const {malborkRef, slupskRef} = props;
    const [toggleTabState, setToggleTabState] = useState(1);

    const scrollToElement = (elementRef) => {
        window.scrollTo({
            left: 0,
            top: elementRef.current.offsetTop - 150,
            behavior: "smooth",
        });
    };

    const toggleTab = (index) => {
        setToggleTabState(index);
    };

    return (
        <>
            <div className="contact-info-container">
                <div className="contact-left-main">
                    <div className="contact-info-title">
                        <h2>
                            Kontakt do <span> Mainsoft</span>
                        </h2>
                    </div>
                    <div className="contact-text-container">
                        <ul>
                            <li>
                                <strong>Telefon: </strong>{" "}
                                <a href="tel:+48572324411">+48 572 324 411</a>
                            </li>
                            <li>
                                <strong>E-mail:</strong>{" "}
                                <a href="mailto:kontakt@mainsoft.pl">
                                    kontakt@mainsoft.pl
                                </a>
                            </li>
                            <li>
                                <strong>NIP:</strong> 5792264087
                            </li>
                            <li>
                                <strong>REGON:</strong> 368852525
                            </li>
                            <li>
                                <strong>KRS:</strong> 0000706370
                            </li>
                            <li>
                                <strong>Nr konta bankowego: </strong>
                                32 1050 1764 1000 0090 8032 7589
                                <Tooltip
                                    TooltipText={"ING Bank Śląski [PLN]"}
                                />
                            </li>
                            <li>
                                <button
                                    className="btn-scroll"
                                    onClick={() => scrollToElement(malborkRef)}
                                >
                                    Oddział w Malborku
                                </button>
                                <button
                                    className="btn-scroll"
                                    onClick={() => scrollToElement(slupskRef)}
                                >
                                    Oddział w Słupsku
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="contact-center-line"></div>

                <div className="contact-right-main">
                    <div className="contact-info-title">
                        <h2>
                            Nasz <span>Zespół</span>
                        </h2>
                    </div>
                    <div className="contact-tabs-container">
                        <div className="contact-tabs-menu">
                            <ul>
                                {ContactTabMenu.map((contactTab, index) => (
                                    <li
                                        key={index}
                                        className={
                                            toggleTabState === contactTab.id
                                                ? "contact-tab-btn active"
                                                : "contact-tab-btn"
                                        }
                                        onClick={() => toggleTab(contactTab.id)}
                                    >
                                        {contactTab.title}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {ContactTabMenu.map((contactTab, index) => (
                            <div
                                key={index}
                                className={
                                    toggleTabState === contactTab.id
                                        ? "contact-tab active"
                                        : "contact-tab"
                                }
                            >
                                <ul>{contactTab.employee}</ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactMainCard;
