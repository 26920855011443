import React from 'react'
import ContactListItem from "./ContactListItem";
export const ContactTabMenu = [
    {
        id: 1,
        title: "IT",
        employee: (
            <>
                <ContactListItem EmployeeName={"Marcin Mielnik"} EmployeePhoneNumber={"607 858 575"} EmployeeEmail={"mmielnik@mainsoft.pl"} />
                <ContactListItem EmployeeName={"Szymon Gajewski"} EmployeePhoneNumber={"505 718 480"} EmployeeEmail={"sgajewski@mainsoft.pl"} />
                <ContactListItem EmployeeName={"Konrad Janowicz"} EmployeePhoneNumber={"696 946 353"} EmployeeEmail={"kjanowicz@mainsoft.pl"} />
                <ContactListItem EmployeeName={"Piotr Szumko"} EmployeePhoneNumber={"604 403 496"} EmployeeEmail={"pszumko@mainsoft.pl"} />
                <ContactListItem EmployeeName={"Łukasz Mielnik"} EmployeePhoneNumber={"600 017 262"} EmployeeEmail={"lmielnik@mainsoft.pl"} />
                <ContactListItem EmployeeName={"Łukasz Kozłowski"} EmployeePhoneNumber={"690 601 417"} EmployeeEmail={"lkozlowski@mainsoft.pl"} />
            </>
        ),
    },
    {
        id: 2,
        title: "Serwis",
        employee: (
            <>
                <ContactListItem EmployeeName={"Zbigniew Braczkowski"} EmployeePhoneNumber={"789 678 785"} EmployeeEmail={"zbraczkowski@mainsoft.pl"} />
            </>
        ),
    },
    {
        id: 3,
        title: "Handel",
        employee: (
            <>
                <ContactListItem EmployeeName={"Rafał Glazer"} EmployeePhoneNumber={"606 964 782"} EmployeeEmail={"rglazer@mainsoft.pl"} />
                <ContactListItem EmployeeName={"Sylwia Szwarc"} EmployeePhoneNumber={"572 324 411"} EmployeeEmail={"sszwarc@mainsoft.pl"} />
                <ContactListItem EmployeeName={"Natalia Korczyc"} EmployeePhoneNumber={"451 627 606"} EmployeeEmail={"nkorczyc@mainsoft.pl"} />
            </>
        ),
    },
    {
        id: 4,
        title: "Strony",
        employee: (
            <>
                <ContactListItem EmployeeName={"Adrian Kaja"} EmployeePhoneNumber={"501 759 665"} EmployeeEmail={"akaja@mainsoft.pl"} />
                <ContactListItem EmployeeName={"Sebastian Kwaśniak"} EmployeePhoneNumber={"501 953 944"} EmployeeEmail={"skwasniak@mainsoft.pl"} />
            </>
        ),
    },
    {
        id: 5,
        title: "Bezpieczeństwo IT",
        employee: (
            <>
                <ContactListItem EmployeeName={"Anna Skowron"} EmployeePhoneNumber={"510 750 199"} EmployeeEmail={"askowron@mainsoft.pl"} />
                <ContactListItem EmployeeName={"Piotr Szumko"} EmployeePhoneNumber={"604 403 496"} EmployeeEmail={"pszumko@mainsoft.pl"} />
                <ContactListItem EmployeeName={"Łukasz Mielnik"} EmployeePhoneNumber={"600 017 262"} EmployeeEmail={"lmielnik@mainsoft.pl"} />
            </>
        ),
    },
];
