import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";

export const MenuItems = [
    {
        id: "1",
        title: <FontAwesomeIcon icon={faHouse} />,
        url: "/",
        cName: "header-menu-btn",
    },
    {
        id: "2",
        title: "O nas",
        url: "/o-nas",
        cName: "header-menu-btn",
    },
    {
        id: "3",
        title: "Usługi",
        url: "/uslugi",
        cName: "header-menu-btn",
        dropdown: true,
        submenu: [
            {
                title: "Obługa informatyczna",
                url: "/error",
            },
            {
                title: "Administracja serwerami i sieciami",
                url: "/error",
            },
            {
                title: "Audyty IT",
                url: "/error",
            },
            {
                title: "Doradztwo IT",
                url: "/error",
            },
        ],
    },
    {
        id: "4",
        title: "Sprzęt i oprogramowanie",
        url: "/zakupy",
        cName: "header-menu-btn",
    },
    {
        id: "5",
        title: "Logowanie",
        url: "/logowanie",
        cName: "header-menu-btn",
        dropdown: true,
        submenu: [
            {
                title: "Mainsoft Poczta",
                url: "https://webmail.mainsoft.pl/",
                isExternal: true,
            },
            {
                title: "Mainsoft Pomoc Pocztowa",
                url: "https://pomoc.mainsoft.pl/",
                isExternal: true,
            },
            {
                title: "Mainsoft Worktime",
                url: "https://worktime.emainsoft.com/login",
                isExternal: true,
            },
        ],
    },
    {
        id: "6",
        title: "Podpis elektroniczny",
        url: "/podpis",
        cName: "header-menu-btn",
    },
    {
        id: "7",
        title: "Kontakt",
        url: "/kontakt",
        cName: "header-menu-btn",
    },
];
