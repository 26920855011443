import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import GraphicWarning from "../../Assets/svg/graphic-warning.svg";
import "./NotFound.css";

const NotFound = () => {
    const [title, setTitle] = useState("Strony nie znaleziono! - Mainsoft");

    useEffect(() => {
        document.title = title;
    }, [title]);
    return (
        <>
            <div className="container-info">
                <div className="box">
                    <div className="break-icon">
                        <img
                            src={GraphicWarning}
                            className="break-img"
                            alt=""
                        />
                    </div>
                    <h2>Strony nie znaleziono!</h2>
                    <p>Strona, której szukasz, nie istnieje (błąd 404)</p>
                    <Link
                        to="/"
                        title="Wróć do strony głównej"
                        className="btn-mainsoft"
                    >
                        Powrót do strony głównej
                    </Link>
                </div>
            </div>
        </>
    );
};

export default NotFound;
