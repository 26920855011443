import React from "react";
import {Link} from "react-router-dom";
import Logo from "../../img/logo.svg";
import "./NavbarLogo.css";

const NavbarLogo = () => {
    return (
        <div className="navbar-logo-container">
            <Link to="/" title="Wróć do strony głównej" className="navbar-logo">
                <img alt="Logo Firmy Mainsoft" className="navbar-logo-img" src={Logo} />
            </Link>
        </div>
    );
};

export default NavbarLogo;
