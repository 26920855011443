import React from "react";
import PagesHero from "../../components/pages-hero/PagesHero";
import SignaturePhoto from "../../img/podpis.jpg";
import "./ElectronicSignature.css";

const ElectronicSinature = () => {
    return (
        <>
            <main className="main-container">
                <PagesHero HeroTitle={"Podpis elektroniczny"} />
                <div className="about-us-container electronic-signature-container">
                    <img src={SignaturePhoto} alt="Podpis elektroniczny" className="signature-photo" />
                    <div className="about-text">
                        <p className="certum-logo">Certum by asseco</p>
                        <h2 className="certum-info">Oferujemy Państwu następujące usługi związane z wdrożeniem certyfikowanego podpisu elektronicznego.</h2>
                        <p>
                            Umożliwiamy wygodny zakup zestawów do podpisu elektronicznego, doradzamy jaki wybrać certyfikat: uniwersalny czy firmowy, drukujemy wszelkie niezbędne dokumenty, umowy, weryfikujemy tożsamość bez konieczności czekania w
                            kolejkach, wysyłamy dokumentację we właściwe miejsca, uruchamiamy pełną funkcjonalność podpisu elektronicznego.
                        </p>
                    </div>
                </div>
            </main>
        </>
    );
};

export default ElectronicSinature;
