import React from "react";
import {Route, Routes} from "react-router-dom";
import About from "./Pages/About/About";
import NotFound from "./Pages/NotFound/NotFound";
import Contact from "./Pages/Contact/Contact";
import Homepage from "./Pages/Homepage/Homepage";
import ElectronicSinature from "./Pages/ElectronicSignature/ElectronicSinature";

const PageRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/kontakt" element={<Contact />} />
            <Route exact path="/o-nas" element={<About />} />
            <Route exact path="/logowanie" element={<NotFound />} />
            <Route exact path="/zakupy" element={<NotFound />} />,
            <Route exact path="/podpis" element={<ElectronicSinature />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default PageRoutes;
