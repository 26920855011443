import React, {useEffect, useState} from "react";
import AboutHomePage from "../../components/homepage/about/aboutHomePage";
import ServicesHomePage from "../../components/homepage/about/servicesHomePage";
import Slider from "../../components/slider/Slider";

const Homepage = () => {
    const [title, setTitle] = useState("Usługi informatyczne - Mainsoft");

    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <main className="main-container">
            <Slider />
            <AboutHomePage />
            <div className="change-color">
                <ServicesHomePage />
            </div>
        </main>
    );
};

export default Homepage;
