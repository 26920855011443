import React, {useState} from "react";
import "./Hamburger.css";

const Hamburger = (props) => {
    const {menuOpen, handleMenuOpen} = props;
    return (
        <>
            <div
                className={
                    props.menuOpen ? "nav-menu-btn" : "nav-menu-btn active"
                }
                onClick={props.handleMenuOpen}
            >
                <button className="nav-hamburger"></button>
            </div>
        </>
    );
};

export default Hamburger;
