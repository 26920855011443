import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import "./ContactListItem.css";

const ContactListItem = (props) => {
    const {EmployeeName, EmployeePhoneNumber, EmployeeEmail} = props;
    return (
        <>
            <li className="contact-list-item">
                <div className="contact-list-title">
                    <h4>{EmployeeName + ":"}</h4>
                </div>
                <a className="contact-list-a" href={"tel:+48 " + EmployeePhoneNumber}>
                    <FontAwesomeIcon icon={faPhone} size="1x" /> <span className="team-card-contact-text">{"+48 " + EmployeePhoneNumber}</span>
                </a>
                <a className="contact-list-a" href={"mailto:" + EmployeeEmail}>
                    <FontAwesomeIcon icon={faEnvelope} size="1x" /> <span className="team-card-contact-text">{EmployeeEmail}</span>
                </a>
            </li>
        </>
    );
};

export default ContactListItem;
