import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {Link, useResolvedPath} from "react-router-dom";
import Dropdown from "./Dropdown";

const MenuItem = (props) => {
    const {item, index} = props;
    const [activeItemMenu, setActiveItemMenu] = useState(1);
    const [dropdown, setDropdown] = useState(false);

    useResolvedPath(item.url);
    const isTabActive = window.location.pathname === item.url;
    return (
        <li key={index} className={!item.dropdown ? "header-menu-item" : "header-menu-item navigation-dropdown"} onClick={() => setActiveItemMenu(item.id)}>
            {item.submenu ? (
                <>
                    <a className={item.cName} aria-expanded={dropdown ? "true" : "false"} onClick={() => setDropdown((prev) => !prev)}>
                        <span className="dropdown-icon">{item.title}</span>
                        <FontAwesomeIcon icon={faAngleDown} size="1x" />
                    </a>
                    <Dropdown submenus={item.submenu} dropdown={dropdown} />
                </>
            ) : (
                <Link to={item.url} className={isTabActive ? item.cName + " active" : item.cName} onClick={props.handleMenuOpen}>
                    {item.title}
                    {console.log(window.location.pathname)}
                </Link>
            )}
        </li>
    );
};

export default MenuItem;
