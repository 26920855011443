import React from "react";
import "./about.css";
import AboutPicture from "../../../img/pic1.svg";
import SpecialBtn from "../../special-btn/SpecialBtn";

const AboutHomePage = () => {
    return (
        <section className="homepage-about-container">
            <div className="homepage-about-header">
                <h1>O nas</h1>
            </div>

            <div className="homepage-about-second-container">
                <div className="homepage-about-text">
                    <p>
                        Firma Mainsoft Sp. z o.o. zajmuję się kompleksową obsługą informatyczną przedsiębiorstw i instytucji. Świadczymy usługi w obszarze szeroko rozumianego outsourcingu IT, bezpieczeństwa informacji, usług handlowych i montażowych
                        z zakresu sieci teleinformatycznych i serwerowni. Zajmujemy się również sprzedażą i wdrożeniami programów klasy ERP oraz programów dla służby zdrowia.
                    </p>
                    <SpecialBtn BtnText={"Czytaj więcej"} BtnLink={"/o-nas"} BtnClass={"special-btn"} />
                </div>

                <div className="homepage-about-img-container">
                    <img src={AboutPicture} alt="Logo Mainsoft" />
                </div>
            </div>
        </section>
    );
};

export default AboutHomePage;
