import React from "react";
import "./AboveNav.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AnyDeskIcon from "../../img/anydesk-svgrepo-com.svg";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare, faLinkedin} from "@fortawesome/free-brands-svg-icons";

const AboveNav = () => {
    return (
        <>
            <div className="over-nav">
                <div className="over-nav-left">
                    <span className="over-nav-item hidden-on-phone">
                        <a
                            href="https://www.facebook.com/mainsoftpl"
                            target="_blank"
                            rel="noopener"
                            className="over-nav-a"
                        >
                            <FontAwesomeIcon
                                icon={faFacebookSquare}
                                size="lg"
                            />
                            <span className="over-nav-text">Facebook</span>
                        </a>
                    </span>
                    <span className="over-nav-item hidden-on-phone">
                        <a
                            href="https://www.linkedin.com/company/mainsoftpl/"
                            target="_blank"
                            rel="noopener"
                            className="over-nav-a"
                        >
                            <FontAwesomeIcon icon={faLinkedin} size="lg" />
                            <span className="over-nav-text">LinkedIn</span>
                        </a>
                    </span>
                </div>
                <div className="over-nav-right">
                    <span className="over-nav-item">
                        <a href="tel:+48572324411" className="over-nav-a">
                            <FontAwesomeIcon icon={faPhone} size="lg" />
                            <span className="over-nav-text">
                                +48 572 324 411
                            </span>
                        </a>
                    </span>

                    <span className="over-nav-item">
                        <a
                            href="mailto:kontakt@mainsoft.pl"
                            className="over-nav-a"
                        >
                            <FontAwesomeIcon icon={faEnvelope} size="lg" />
                            <span className="over-nav-text">
                                kontakt@mainsoft.pl
                            </span>
                        </a>
                    </span>

                    <span className="over-nav-item hidden-on-phone">
                        <a
                            href="https://download.anydesk.com/AnyDesk.exe"
                            className="over-nav-a"
                            title="Pobierz program AnyDesk"
                            download
                        >
                            <img
                                src={AnyDeskIcon}
                                alt="Pobierz AnyDesk"
                                className="anydesk-icon"
                            />
                            <span className="over-nav-text">
                                <strong>Pobierz AnyDesk</strong>
                            </span>
                        </a>
                    </span>
                </div>
            </div>
        </>
    );
};

export default AboveNav;
