import React, {useRef} from "react";
import ContactMainCard from "../../components/contact/ContactMainCard";
import ContactMainInfo from "../../components/contact/ContactMainInfo";
import PagesHero from "../../components/pages-hero/PagesHero";
import ContactSecondImg from "../../img/contact-malbork.jpg";
import ContactThirdImg from "../../img/contact-slupsk.jpg";
import "./Contact.css";

const Contact = () => {
    const malborkRef = useRef(null);
    const slupskRef = useRef(null);

    const ContactMainInfoItems = [
        {
            references: malborkRef,
            contactMainImg: ContactSecondImg,
            contactImgAlt: "Obraz przedstawiający Malbork",
            contactIsReversed: true,
            contactTitle: "Malborku",
            contactLink: "https://www.google.com/maps?ll=54.035858,19.024649&z=16&t=m&hl=pl&gl=PL&mapclient=embed&cid=7101853106038052063",
            contactAdressLine1: "ul. Stare Miasto 27/6",
            contactAdressLine2: "82-200 Malbork (Pomorskie)",
            contactIframe:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d828.4204896200124!2d19.025026036683016!3d54.03601447400439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd74dcc3ba3fc3%3A0x628ed9c5d06714df!2sMAINSOFT%20Sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1601989669919!5m2!1spl!2spl",
        },
        {
            references: slupskRef,
            contactMainImg: ContactThirdImg,
            contactImgAlt: "Obraz przedstawiający Słupsk",
            contactIsReversed: false,
            contactTitle: "Słupsku",
            contactLink: "https://www.google.com/maps/place/Portowa+13b,+76-200+S%C5%82upsk/@54.483886,17.016039,17z/data=!3m1!4b1!4m6!3m5!1s0x46fe174b3bd74b59:0xb99348324d542196!8m2!3d54.483886!4d17.016039!16s%252Fg%252F11gfjs6db4",
            contactAdressLine1: "ul. Portowa 13b",
            contactAdressLine2: "76-200 Słupsk (Pomorskie)",
            contactIframe:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2317.812723559634!2d17.016039!3d54.483886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fe174b3bd74b59%3A0xb99348324d542196!2sPortowa%2013b%2C%2076-200%20S%C5%82upsk!5e0!3m2!1spl!2spl!4v1690798660683!5m2!1spl!2spl",
        },
    ];

    return (
        <>
            <main className="main-container">
                <PagesHero HeroTitle={"KONTAKT"} />
                <ContactMainCard malborkRef={malborkRef} slupskRef={slupskRef} />
                <div className="contact-title">
                    <h2>NASZE ODDZIAŁY</h2>
                </div>
                <div ref={malborkRef}></div>

                {ContactMainInfoItems.map((e, index) => {
                    return (
                        <ContactMainInfo
                            References={e.references}
                            key={index}
                            ContactMainImg={e.contactMainImg}
                            ContactImgAlt={e.contactImgAlt}
                            ContactIsReversed={e.contactIsReversed}
                            ContactTitle={e.contactTitle}
                            ContactAdressLine1={e.contactAdressLine1}
                            ContactAdressLine2={e.contactAdressLine2}
                            ContactLink={e.contactLink}
                            ContactIframe={e.contactIframe}
                        />
                    );
                })}
            </main>
        </>
    );
};

export default Contact;
