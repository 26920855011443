import React from "react";
import "./PagesHero.css";

const PagesHero = (props) => {
    return (
        <div className="page-hero">
            <h1 className="page-hero-heading">{props.HeroTitle}</h1>
        </div>
    );
};

export default PagesHero;
